import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "body-width pb-32" }
const _hoisted_2 = { class: "page-head-menu" }
const _hoisted_3 = { class: "page-title mr-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MyRespondents = _resolveComponent("MyRespondents", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("assessments.My_respondents")), 1)
    ]),
    _createVNode(_component_MyRespondents)
  ]))
}